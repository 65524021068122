<template>
  <v-card
    class="v-card--wizard"
    elevation="12"
  >
    <div class="d-flex grow flex-wrap">
      <v-sheet color="success" elevation="6" class="ml-3 pa-7 mt-n4" dark>
        <v-icon size="32">mdi-auto-fix</v-icon>
      </v-sheet>

      <div>
        <div class="card-title font-weight-light ml-4 mt-3">
          <h3>New Event Wizard</h3>
        </div>
      </div>
      <v-spacer/>
      <div class="ma-2">
        <v-icon large @click="$emit('close')">mdi-close-circle</v-icon>
      </div>
    </div>

    <div class="text-center display-1 grey--text font-weight-light mt-6 mb-6 mx-3">
      <slot name="header"/>
    </div>

    


    <v-tabs
      ref="tabs"
      v-model="internalValue"
      background-color="green lighten-5"
      color="white"
      grow
      slider-size="50"
    >
      <v-tabs-slider
        class="mt-1"
        color="success"
      />

      <v-tab class="mx-0 px-0 customTabTitle"
        v-for="(item, i) in items"
        :key="i"
        :ripple="false"
        :disabled="!availableSteps.includes(i)"
      >
        {{ item }}
      </v-tab>
    </v-tabs>

    <div class="my-6" />

    <v-card-text>
      <v-tabs-items v-model="internalValue">
        <slot />
      </v-tabs-items>
    </v-card-text>

    <v-card-actions class="pb-4 pa-4">
      <v-btn
        :disabled="internalValue === 0"
        class="white--text"
        color="grey darken-2"
        min-width="125"
        @click="$emit('click:prev')"
      >
        Previous
      </v-btn>

      <v-spacer />

      <v-btn
        color="success"
        min-width="100"
        :disabled="nextDisabled"
        @click="$emit('click:next')"
      >
        {{ internalValue === items.length - 1 ? 'Finish' : 'Next' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  // Mixins
  import Proxyable from 'vuetify/lib/mixins/proxyable'

  export default {
    name: 'BaseMaterialWizard',

    mixins: [Proxyable],

    props: {
      nextDisabled: false,
      availableSteps: {
        type: Array,
        default: () => ([]),
      },
      validatedSteps: {
        type: Array,
        default: () => ([]),
      },
      items: {
        type: Array,
        default: () => ([]),
      },
    },
  }
</script>

<style lang="sass">
  .v-card--wizard
    overflow: visible

    .v-tabs-bar
      height: 40px
      padding: 0 8px

    .v-tabs-slider-wrapper
      overflow: visible

    .v-tabs-slider
      border-radius: 4px

    .v-tabs-slider-wrapper
      contain: initial
      z-index: 0

    .customTabTitle 
      min-width: 0
      font-size: 14px
</style>
